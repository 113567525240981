
import { Link } from "react-router-dom";
import React, { useRef, useEffect, useState, useMemo } from 'react';
import './MainMap.css'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import * as turf from '@turf/turf'
import { LayersTOC } from '../../mapLayouts/LayersTOC/LayersTOC';

import { unit } from 'mathjs'


import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import SearchControl from "../../MapControls/SearchControl";
import { Paper } from "@mui/material";
import ResidenceRoomCard from "../../mapLayouts/Popups/ResidenceRooom";

mapboxgl.accessToken = 'pk.eyJ1IjoiY2hhbG9lbXBob2wiLCJhIjoiY2w0a3JidXJtMG0yYTNpbnhtdnd6cGh0dCJ9.CpVWidx8WhlkRkdK1zTIbw';

export function MainMap(props) {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const searchables = useRef()
  const searchingKeywords = useRef()
  const popup = useRef()
  const searchableBBox = useRef()

  const draw = useRef(null);
//Longitude: 101.1866 | Latitude: 14.6534 | Zoom: 15.12 | Bearing: 0.00 | Pitch: 0.00
  const start = [101.1866, 14.6534];
  const [_zoom, _bearing, _pitch] = [15, 0, 0]
  const [lng, setLng] = useState(start[0]);
  const [lat, setLat] = useState(start[1]);
  const [zoom, setZoom] = useState(_zoom); //15
  const [bearing, setBearing] = useState(_bearing); //-108
  const [pitch, setPitch] = useState(_pitch); //76
//   const [compareMode, setCompareMode] = useState(false);
  const [toggleSymbol, setToggleSymbol] = useState("▶︎");
  const [mode, setMode] = useState("");
  // const [searchingLayer, setSearchingLayer] = useState('');
  const searchingLayer = useRef()

  //map data sources
  const ortho = require('../../MapData/nkrafaortho.json')
  const ndvi = require('../../MapData/nkrafandvi.json')
  const admins = require('../../MapData/vectorAdminSrc.json')
  const constructions = require('../../MapData/vectorConstructionSrc.json')
  const essentialLayers = {...ortho, ...ndvi, ...constructions} //...admins,


  const mapIds = Object.entries(essentialLayers).reduce((p, [name, con]) => {
    return {...p, [name] : con.info.desc}
  }, {'nkrafa-dem-layer': 'ชั้นความสูง DEM'});
  // Enumerate ids of the layers.
  const toggleableLayerIds = Object.keys(essentialLayers).reduce((p, name) => {
    Array.isArray(p) ? p.push(name) : p = name
    return p
  }, [])

  //['nkrafa-ortho-6508-layer', 'nkrafa-ortho-6509-layer', 'nkrafa-dem-layer', 'provinces', 'roads', 'buildings']; //'contours', 'museums',
  //['provinces', 'amphoes', 'tambols', 'sky', 'nkrafa-ortho-6508-layer']; //'contours', 'museums',
  // If these two layers were not added to the map, abort

  const visibleLayers = Object.entries(essentialLayers).reduce((p, [name, con]) => {
    if (con.info.visible) Array.isArray(p) ? p.push(name) : p = name
    return p
  }, [])
  //['nkrafa-ortho-6508-layer', 'nkrafa-ortho-6509-layer', 'roads', 'buildings']//['provinces']

  const [spinners, setSpinners] = useState(toggleableLayerIds.reduce((p, id) => ({...p, [id]: <></>}), {}));


  function zoomToFeature(feature) {

    let center = feature.geometry.coordinates
    let polygon = turf.polygon(center);
    let centroid = turf.centroid(polygon);
    let flyParams = {
      // These options control the ending camera position: centered at
      // the target, at zoom level 9, and north up.
      center: centroid.geometry.coordinates,
      // These options control the flight curve, making it move
      // slowly and zoom out almost completely before starting
      // to pan.
      // zoom,
      // bearing: 45,
      // pitch: 20,
      speed: 1.5, // make the flying slow
      curve: 1, // change the speed at which it zooms out

      // This can be any easing function: it takes a number between
      // 0 and 1 and returns another number between 0 and 1.
      easing: (t) => t,

      // this animation is considered essential with respect to prefers-reduced-motion
      essential: true
    }
    // map.flyTo({center: centroid.geometry.coordinates, zoom: 12});

    map.current.flyTo(flyParams);

  }

  useEffect(() => {

    if (map.current) return; // initialize map only once


    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      // style: 'mapbox://styles/mapbox/streets-v11',
      style: 'mapbox://styles/chaloemphol/cjkje3cwt17e72smseq2pmmxu',// 'mapbox://styles/chaloemphol/clasf7ipf00dp14mpio2dnq8h',//'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
      center: [lng, lat],
      pitch,
      bearing,
      zoom,
    });

    popup.current = new mapboxgl.Popup({
      closeButton: false
    });

    // map.current.addControl(new mapboxgl.FullscreenControl());
    map.current.addControl(new mapboxgl.NavigationControl());

    if (!draw.current) draw.current = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        line_string: true,
        // combine_features: true,
        // uncombine_features: true,
        polygon: true,
        trash: true
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      // defaultMode: 'draw_polygon'
    });
    map.current.addControl(draw.current);


    // Search Control


    // const searchControl = new SearchControl();

    // map.current.addControl(searchControl);


    const filterEl = document.getElementById('feature-filter');
    const listingEl = document.getElementById('feature-listing');


    function renderListings(features) {

      console.log('searchingLayer in renderListings', searchingLayer.current);

      const empty = document.createElement('p');
      // Clear any existing listings
      listingEl.innerHTML = '';
      if (features.length) {
        for (const feature of features) {


          // console.log('feature', feature);

          // const labels = Object.entries()
          const itemLink = document.createElement('a');
          let label = '-'

          // console.log(`feature.properties`, feature.properties['ROOM'], feature.properties['HOUSE'], feature.properties['B_TYPE']);

          switch (feature.properties['ROOM']) {
            case undefined:
              switch (feature.properties['HOUSE']) {
                case undefined:
                  label = `🏢 ${
                    feature.properties["NAME"] !== undefined
                      ? feature.properties["NAME"]
                      : feature.properties["B_TYPE"]
                  }`;
                  break;

                default:
                  label = `🏢 ${feature.properties['HOUSE']} -  ${feature.properties['FLNAME']}`;
                  break;
              }
              break;
            default:
              label = `ห้อง ${feature.properties["ROOM"]}\n${[
            ["B_CODE", 'อาคาร '],
            ["FLOOR", 'ชั้น '],
            ["PERSON_1"],
            ["PERSON_2", "พักกับ"],
            ["REMARK_1", "หมายเหตุ: "],
          ]
            .map(([key, prefix, suffix]) => feature.properties[key] && [prefix, feature.properties[key], suffix].join(""))
            .filter((v) => v && v.trim())
            .join(" | ")}`;
              break;

          }
          itemLink.href = '#' //feature.properties.wikipedia;
          itemLink.target = '_self';
          itemLink.textContent = label;
          itemLink.addEventListener('mousedown', () => zoomToFeature(feature))

          // itemLink.onmousedown = () => zoomToFeature(feature)
          itemLink.addEventListener('mouseover', () => {

            let center = feature.geometry.coordinates
            let polygon = turf.polygon(center);
            let centroid = turf.centroid(polygon);
            // Highlight corresponding feature on the map

            // const htmlContent = `<strong>ห้อง ${feature.properties["ROOM"]} ${feature.properties["B_TYPE"]}</strong><br />${[
            //   ["FLOOR", 'ชั้น '],
            //   ["PERSON_1"],
            //   ["PERSON_2", "พักกับ"],
            //   ["REMARK_1", "หมายเหตุ: "],
            // ]
            //   .map(([key, prefix, suffix]) => feature.properties[key] && [prefix, feature.properties[key], suffix].join(""))
            //   .filter((v) => v && v.trim())
            //   .join(" | ")}`;

            let html;

            switch (feature.properties['ROOM']) {
              case undefined:

              switch (feature.properties['HOUSE']) {
                case undefined:
                  html = `<strong>${feature.properties["NAME"] !== undefined ? feature.properties["NAME"] : feature.properties["B_TYPE"]}</strong>`;
                  break;

                default:
                  html = `<strong>${feature.properties["HOUSE"]} - ${feature.properties["FLNAME"]}</strong>`;
                  break;
              }
                break;
              default:
                html = `<strong>ห้อง ${feature.properties["ROOM"]} ${feature.properties["B_TYPE"]}</strong><br />${[

              ["B_CODE", 'อาคาร '],
              ["FLOOR", 'ชั้น '],
              ["PERSON_1"],
              ["PERSON_2", "พักกับ"],
              ["REMARK_1", "หมายเหตุ: "],
            ]
              .map(([key, prefix, suffix]) => feature.properties[key] && [prefix, feature.properties[key], suffix].join(""))
              .filter((v) => v && v.trim())
              .join(" | ")}`;
                break;
            }
            popup.current
            .setOffset([0, -15])
            .setLngLat(centroid.geometry.coordinates)

            .setHTML(html)
            .addTo(map.current);
          });

          listingEl.appendChild(itemLink);
        }

        // Show the filter input
        filterEl.parentNode.style.display = 'block';
      } else if (features.length === 0 && filterEl.value !== '') {
          empty.textContent = 'ไม่พบผลลัพธ์ค้นหา';
          listingEl.appendChild(empty);
      } else {
          empty.textContent = 'เลื่อนแผนที่เพื่อค้นหาและดูผลลัพธ์';
          listingEl.appendChild(empty);

          // Hide the filter input
          // filterEl.parentNode.style.display = 'none';

          // remove features filter

          // const keyword = 'AREA_SQM'
          Array.from(searchingLayer.current).forEach((l) => {
            const keywords = ["AREA_SQM", "FLNAME", "B_TYPE"]; // คำหลายคำที่ต้องการตรวจสอบ
            const filter = [
              "any", // ใช้ any เพื่อให้ตรวจสอบว่าอย่างน้อยหนึ่งคำต้องมีอยู่
              ...keywords.map((keyword) => ["has", keyword]),
            ];
            map.current.setFilter(l, filter);
          });

      }
    }


    map.current.on('load', () => {

      // const layerList = document.getElementById('basemaps_menu');
      // const inputs = layerList.getElementsByTagName('input');

      // for (const input of inputs) {
      //   input.onclick = (layer) => {
      //     const layerId = layer.target.id;
      //     map.current.setStyle('mapbox://styles/mapbox/' + layerId);
      //   };
      // }



      toggleSidebar('left');


      // toggleableLayerIds.forEach(id => {
      //   console.log('====================================');
      //   console.log('checking visibility', id);
      //   console.log('====================================');
      //   toggleVisibility(id)

      // });

      // map.current.addSource('dot-point', {
      //   'type': 'geojson',
      //   'data': {
      //     'type': 'FeatureCollection',
      //     'features': [{
      //       'type': 'Feature',
      //       'geometry': {
      //         'type': 'Point',
      //         'coordinates': [lng, lat] // icon position [lng, lat]
      //       }
      //     }]
      //   }
      // });
      // map.current.addLayer({
      //   'id': 'layer-with-pulsing-dot',
      //   'type': 'symbol',
      //   'source': 'dot-point',
      //   'layout': {
      //     'icon-image': 'pulsing-dot'
      //   }
      // });

      ////http://10.229.4.192/geoserver/uas4gis/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=uas4gis%3AProvince&outputFormat=application%2Fjson


      // map.current.addSource('museums', {
      //   type: 'vector',
      //   url: 'mapbox://mapbox.2opop9hr'
      // });

      // map.current.addLayer({
      //   'id': 'museums',
      //   'type': 'circle',
      //   'source': 'museums',
      //   'layout': {
      //     // Make the layer visible by default.
      //     'visibility': 'visible'
      //     },
      //     'paint': {
      //       'circle-radius': 8,
      //       'circle-color': 'rgba(55,148,179,1)'
      //       },
      //     'source-layer': 'museum-cusco'
      //   });

      // Add the Mapbox Terrain v2 vector tileset. Read more about
      // the structure of data in this tileset in the documentation:
      // https://docs.mapbox.com/vector-tiles/reference/mapbox-terrain-v2/
      // map.current.addSource('contours', {
      // type: 'vector',
      // url: 'mapbox://mapbox.mapbox-terrain-v2'
      // });
      // map.current.addLayer({
      //   'id': 'contours',
      //   'type': 'line',
      //   'source': 'contours',
      //   'source-layer': 'contour',
      //   'layout': {
      //     // Make the layer visible by default.
      //     'visibility': 'visible',
      //     'line-join': 'round',
      //     'line-cap': 'round'
      //   },
      //   'paint': {
      //   'line-color': '#877b59',
      //   'line-width': 1
      // }
      // });
    });



    function toggleVisibility(id) {
      if (!visibleLayers.includes(id)) map.current.setLayoutProperty(
        id,
        'visibility',
        'none'
      );
    }


    var loadSource = () => {
      if (map.current.isStyleLoaded()) {


        // When a click event occurs on a feature in the states layer,
        // open a popup at the location of the click, with description
        // HTML from the click event's properties.
        map.current.on('click', 'provinces', (e) => {
          new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(e.features[0].properties.name_t)
          .addTo(map.current);
          });

          // Change the cursor to a pointer when
          // the mouse is over the states layer.
          map.current.on('mouseenter', 'provinces', () => {
          map.current.getCanvas().style.cursor = 'pointer';
          });

          // Change the cursor back to a pointer
          // when it leaves the states layer.
          map.current.on('mouseleave', 'provinces', () => {
          map.current.getCanvas().style.cursor = '';
          });

                    //SKY
        // add a sky layer that will show when the map is highly pitched

        if (!map.current.getLayer('sky')) map.current.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
          }
        });

        //  MARK Mapbox Terrain

        if (!map.current.getSource('mapbox-dem')) map.current.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': 14
        });
        // add the DEM source as a terrain layer with exaggerated height
        // map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });


        // MARK:- DEM

        if (!map.current.getSource('nkrafa-dem')) map.current.addSource('nkrafa-dem', {
          "type": "raster-dem",
          "url": "mapbox://chaloemphol.aqjbpzug",
          "tileSize": 256
      });

      if (!map.current.getLayer('nkrafa-dem-layer')) {
        map.current.addLayer({
            'id': 'nkrafa-dem-layer',
            'type': 'hillshade',
            'source': 'nkrafa-dem',
            'paint': {}
          }
        );

        toggleVisibility('nkrafa-dem-layer')
      }


        // MARK:- RASTER

      /**
       * BBOX ให้ใช้ {bbox-epsg-3857}
       *
      //  */


      Object.entries(ortho).sort((a, b) => a[1].info.date - b[1].info.date).forEach(([name, con]) => {
        if (!map.current.getSource(con.layer.source)) {
        console.log(name);
          map.current.addSource(con.layer.source, con.src);
        }
        if (!map.current.getLayer(name)) {
          map.current.addLayer(con.layer);
          toggleVisibility(name)
        }
      });

      Object.entries(ndvi).sort((a, b) => a[1].info.date - b[1].info.date).forEach(([name, con]) => {
        if (!map.current.getSource(con.layer.source)) {
        console.log(name);
          map.current.addSource(con.layer.source, con.src);
        }
        if (!map.current.getLayer(name)) {
          map.current.addLayer(con.layer);
          toggleVisibility(name)
        }
      });

        // Admin areas
        // Object.entries(admins).sort((a, b) => a[1].id - b[1].id).forEach(([name, con]) => {
        //   if (!map.current.getSource(con.layer.source)) map.current.addSource(con.layer.source, con.src);
        //   if (!map.current.getLayer(name)) {
        //     map.current.addLayer(con.layer);
        //     toggleVisibility(name)
        //   }
        // });

        // Buildings and roads
        if (!searchingKeywords.current) searchingKeywords.current =  new Set();
        Object.entries(constructions).forEach(([name, con]) => {

          if (con.searchable) {
            console.log('setting searchable:', name, con);
            // setSearchingLayer(`${name}`)
            if (!searchingLayer.current) searchingLayer.current = new Set()
            searchingLayer.current.add(name)
          const uniqueKeys = Object.entries(con.searchable.fields)
            .filter(([, field]) => field.uniquekey)
            .map(([key, _]) => key);
          searchingKeywords.current =  new Set([...searchingKeywords.current, ...uniqueKeys])

          }

          if (!map.current.getSource(con.layer.source)) {
            console.log(name);
            console.log('con.src', con.src);

            map.current.addSource(con.layer.source, con.src);

          }
          if (!map.current.getLayer(name)) {
            map.current.addLayer(con.layer);
            toggleVisibility(name)

            if (con.label && !map.current.getLayer(name + "-label")) {
              map.current.addLayer(con.label);
              // ย้าย label ให้อยู่ข้างหน้า layer หลัก
              map.current.moveLayer(name + "-label", name);

            }
          }

          if (con.info.extrude && !map.current.getLayer(con.extrude.id)) {
            map.current.addLayer(con.extrude)
            searchingLayer.current.add(con.extrude.id)
                // ย้าย extrude ไว้ข้างหลัง label ด้วย
            if (con.label) {
              map.current.moveLayer(name + "-label", con.extrude.id);
            }
            
          }

        });


        // console.log('searchingLayer in loadSource', searchingLayer.current);

        // let features = []
        // Array.from(searchingLayer.current).forEach(sourceId => {
        //   let f = map.current.querySourceFeatures({  sourceId : 'buildings-source' })
        //   console.log('====================================');
        //   console.log(f);
        //   console.log('====================================');
        //   features = [...features, ...map.current.querySourceFeatures({  sourceId : sourceId })];
        // })

        // if (features) {
        //   const uniqueFeatures = getUniqueFeatures(features, 'AREA_SQM');
        //   // Populate features for the listing overlay.
        //     renderListings(uniqueFeatures);

        //   // Clear the input container
        //   //TODO:- uncomment here
        //   filterEl.value = '';

        //   // Store the current features in sn `airports` variable to
        //   // later use for filtering on `keyup`.
        //   searchables.current = uniqueFeatures;

        // console.log('searchables in loadSource', searchables.current);

        // }



        const layers = document.getElementById('menu');

        if (!document.getElementById('toggleTerrain')) {
          const toggleTerrainCB = document.createElement('input');
          toggleTerrainCB.type = 'checkbox';
          toggleTerrainCB.id = 'toggleTerrain'; // need unique Ids!
          toggleTerrainCB.checked = (bearing === 0 && pitch === 0) ? '' : map.current.getTerrain() ? 'checked' : '';
          toggleTerrainCB.onclick = (e) => toggleTerrain(e.target);
          layers.appendChild(toggleTerrainCB);

          const toggleTerrainLabel = document.createElement("label");
          toggleTerrainLabel.innerText = "แสดง Terrain"
          toggleTerrainLabel.htmlFor =  "toggleTerrain" ;
          layers.appendChild(toggleTerrainLabel);
        }

        if (toggleableLayerIds.some(l => !map.current.getLayer(l))) {
          return;
        }


        let layerGroupsSet = new Set();
let latestOrthoLayers = [];
let latestNdviLayers = [];

// Helper function to get the latest two layers by date
const getLatestLayers = (layers, type) => {
  return Object.entries(layers)
    .filter(([_, layer]) => layer.info.group === type)
    .sort((a, b) => new Date(b[1].info.date) - new Date(a[1].info.date)) // Sort by date
    .slice(0, 2); // Get the latest 2
};

// Fetch the latest ortho and NDVI layers
latestOrthoLayers = getLatestLayers(essentialLayers, "ภาพถ่ายทางอากาศ");
latestNdviLayers = getLatestLayers(essentialLayers, "NDVI");

// Group layers by their group type (e.g., ortho, NDVI, etc.)
Object.values(essentialLayers).forEach(con => {
  // Add all layers to the group set
  layerGroupsSet.add(con.info.group);
});

// Create UI elements for each group
layerGroupsSet.forEach(group => {
  const name = document.createElement('p');
  name.textContent = group;
  name.id = group;
  name.style.textDecoration = 'underline';
  name.style.marginTop = '10px';
  name.style.textAlign = 'center';
  if (!document.getElementById(group)) layers.appendChild(name);

  if (group === "Vectors") {
    // For Vectors group, show all layers as links without a dropdown
    const vectorLayers = Object.entries(essentialLayers)
      .filter(([_, con]) => con.info.group === "Vectors");

    vectorLayers.forEach(([id, con]) => {
      const link = document.createElement('a');
      link.id = id;
      link.href = '#';
      link.textContent = mapIds[id] || id;
      link.className = visibleLayers.includes(id) ? 'active' : '';

      link.onclick = function (e) {
        const clickedLayer = this.id;
        const clickedLayerLabel = clickedLayer + '-label';
        const clickedLayerExtrude = clickedLayer + '-extrude';

        const allLayers = [clickedLayer, clickedLayerLabel, clickedLayerExtrude];

        e.preventDefault();
        e.stopPropagation();

        const visibility = map.current.getLayoutProperty(clickedLayer, 'visibility');
        if (!visibility || visibility === 'visible') {
          allLayers.forEach(layer => {
            if (map.current.getLayer(layer)) map.current.setLayoutProperty(layer,
              'visibility',
              'none');
          });
          this.className = '';
        } else {
          allLayers.forEach(layer => {
            if (map.current.getLayer(layer)) map.current.setLayoutProperty(layer,
              'visibility',
              'visible');
          });
          this.className = 'active';
        }
      };
      layers.appendChild(link);
    });
  } else {
    // Create a dropdown for older layers for non-Vector groups
    const dropdown = document.createElement('select');
    dropdown.id = `dropdown-${group}`;
    dropdown.style.display = 'block';
    dropdown.style.margin = '10px auto';

    // Populate dropdown with older layers
    const olderLayers = Object.entries(essentialLayers)
      .filter(([_, con]) => con.info.group === group && con.info.group !== "NDVI")
      .sort((a, b) => new Date(b[1].info.date) - new Date(a[1].info.date)) // Sort by date
      .slice(2); // Skip the latest two layers

    olderLayers.forEach(([id, con]) => {
      const option = document.createElement('option');
      option.value = id;
      option.textContent = mapIds[id] || id;
      dropdown.appendChild(option);
    });

    // Handle layer toggling from the dropdown
    dropdown.onchange = function () {
      const selectedLayer = this.value;
      const visibility = map.current.getLayoutProperty(selectedLayer, 'visibility');
      if (!visibility || visibility === 'visible') {
        map.current.setLayoutProperty(selectedLayer, 'visibility', 'none');
      } else {
        map.current.setLayoutProperty(selectedLayer, 'visibility', 'visible');
      }
    };

    // Append the dropdown only if there are older layers
    if (olderLayers.length > 0) {
      layers.appendChild(dropdown);
    }

    // Add latest two layers directly
    const latestLayers = group === "ภาพถ่ายทางอากาศ" ? latestOrthoLayers : latestNdviLayers;
    latestLayers.forEach(([id, con]) => {
      const link = document.createElement('a');
      link.id = id;
      link.href = '#';
      link.textContent = mapIds[id] || id;
      link.className = visibleLayers.includes(id) ? 'active' : '';

      link.onclick = function (e) {
        const clickedLayer = this.id;
        const clickedLayerLabel = clickedLayer + '-label';
        const clickedLayerExtrude = clickedLayer + '-extrude';

        const allLayers = [clickedLayer, clickedLayerLabel, clickedLayerExtrude];

        e.preventDefault();
        e.stopPropagation();

        const visibility = map.current.getLayoutProperty(clickedLayer, 'visibility');
        if (!visibility || visibility === 'visible') {
          allLayers.forEach(layer => {
            if (map.current.getLayer(layer)) map.current.setLayoutProperty(layer,
              'visibility',
              'none');
          });
          this.className = '';
        } else {
          allLayers.forEach(layer => {
            if (map.current.getLayer(layer)) map.current.setLayoutProperty(layer,
              'visibility',
              'visible');
          });
          this.className = 'active';
        }
      };
      layers.appendChild(link);
    });
  }
});



        map.current.off('data', loadSource);


        // Call this function on initialization
        // passing an empty array to render an empty state
        renderListings([]);
      }
    }

    //filtration:

      map.current.on('movestart', () => {
        if (!searchables.current) {

        // reset features filter as the map starts moving
          console.log('searchingLayer in movestart', searchingLayer.current);
          // const keyword = 'AREA_SQM'
          Array.from(searchingLayer.current).forEach(l => {

            const keywords = ["AREA_SQM", "FLNAME", "B_TYPE"]; // คำหลายคำที่ต้องการตรวจสอบ
            const filter = [
              "any", // ใช้ any เพื่อให้ตรวจสอบว่าอย่างน้อยหนึ่งคำต้องมีอยู่
              ...keywords.map((keyword) => ["has", keyword]),
            ];
            map.current.setFilter(l, filter);
            if (map.current.getLayer(l + "-label")) map.current.setFilter(l + "-label", filter)
          })
        }
      });

      map.current.on('moveend', () => {

        if (!searchables.current) {

          console.log('searchingLayer in moveend', searchingLayer.current);

          const features = map.current.queryRenderedFeatures({ layers: Array.from(searchingLayer.current) });

          if (features) {
            const uniqueFeatures = getUniqueFeatures(features, [...searchingKeywords.current]);
            // Populate features for the listing overlay.
              renderListings([]);

            // Clear the input container
            //TODO:- uncomment here
            filterEl.value = '';

            // Store the current features in sn `airports` variable to
            // later use for filtering on `keyup`.
            searchables.current = uniqueFeatures;
          }
        }

      });


    //draw tools
    map.current.on('draw.create', updateCalculation);
    map.current.on('draw.delete', updateCalculation);
    map.current.on('draw.update', updateCalculation);
    map.current.on('draw.modechange', updateCalculation);
    map.current.on('draw.render', updateCalculation);

    map.current.on('data', loadSource);

    map.current.on('sourcedata', (e) => {

          if (searchingLayer.current) {

             var fs = Array.from(searchingLayer.current).reduce((p, c) => {

              if (`${e.sourceId}` !== (c + '-source') || !e.isSourceLoaded || !map.current.getSource(c + '-source')) return p

              var f = map.current.querySourceFeatures(c + '-source')

              if (f.length === 0) return p
              // console.log('====================================');
              // console.log(constructions[c].src);
              // console.log('====================================');
              // let newbbox = turf.bbox(constructions[c].src);
              // console.log('====================================');
              // console.log(newbbox);
              // console.log('====================================');
              return Array.isArray(p) ? [...p, ...f] : f
              // [...f]
            }, [])
          }

          if (fs && fs.length) {
            searchableBBox.current = turf.bbox({
              type: 'FeatureCollection',
              features: fs
            });
          }


      // console.log(e);
      // if (e.isSourceLoaded) {
      //   setSpinners(o => ({...o, [e.sourceId] : <>spinning</>}))
      // } else {
      //   setSpinners(o => ({...o, [e.sourceId] : <></>}))
      // }
    })

      // After the last frame rendered before the map enters an "idle" state.
      map.current.on('idle', () => {

        // if (map.current) console.log(map.current.getCenter(), map.current.getZoom());



      // map.current.setLayoutProperty('provinces', 'text-field', [
      //     'format',
      //     ['get', 'name_e'],
      //     { 'font-scale': 1.2 }]);

    });


    function updateCalculation(e) {

        console.log(e.type, draw.current.getMode());
        let mode = draw.current.getMode()

        let data = draw.current.getAll();
        // if (e.type !== 'draw.render') console.log(data);
        const answer = document.getElementById('calculated-area');
        let headerText = document.createElement('p')
        headerText.id = 'headerText'

        const calculationBox = document.getElementById('calculation-box');
        if (!document.getElementById('headerText') && calculationBox) calculationBox.prepend(headerText)

        calculationBox.style.display = data.features.length > 0 ? 'block' : 'none'

        const searchContainer = document.getElementById('search_container');
        if (searchContainer) searchContainer.style.maxHeight =  data.features.length > 0 ?'50%' : '80%'

        const ButtonGroupRight = document.getElementById('button-group-right');
        if (ButtonGroupRight) ButtonGroupRight.style.maxHeight =  data.features.length > 0 ?'50%' : '80%'

        if (e.type === 'draw.update') {
          // switch (data.features.) {
          //   case value:

          //     break;

          //   default:
          //     break;
          // }
        }
        switch (mode) {
          case 'draw_polygon':

            document.getElementById('headerText').textContent = 'ขนาดพื้นที่รวม'
            data.features = data.features.filter(f => f.geometry.type === 'Polygon')
            console.log(data);

            if (data.features.length > 0) {
              const displayingUnit = 'm2'
              const area = turf.area(data);
              // Restrict the area to 2 decimal points.
              // const rounded_area = Math.round(area * 100) / 100;
              answer.innerHTML = `<div>${unit(area, displayingUnit).format({notation: 'fixed', precision: 2}).toString()}</div>` //; //<p><strong>${rounded_area} sqm.</strong></p>
            } else {
              answer.innerHTML = '';
              // if (e.type !== 'draw.delete')
              //   alert('Click the map to draw a polygon.');
            }
            break;

          case 'draw_line_string':

            document.getElementById('headerText').textContent = 'ระยะทางรวม'
            data.features = data.features.filter(f => f.geometry.type === 'LineString')
            console.log(data);

            if (data.features.length > 0) {
              const displayingUnit = 'meters'
              const length = turf.length(data, { units : 'meters'});
              // Restrict the area to 2 decimal points.
              // console.log(length);
              // const rounded_length = length.toFixed(3);
              answer.innerHTML = `<div>${unit(length, displayingUnit).format({notation: 'fixed', precision: 2}).toString()}</div>`
            } else {
              answer.innerHTML = '';
              // if (e.type !== 'draw.delete')
              //   alert('Click the map to draw a polygon.');
            }
            break;


          default:
            break;
        }



    }

    // setHandleResetView(() => {
    //       // depending on whether we're currently at point a or b, aim for
    //       // point a or b
    //       // const target = isAtStart ? end : start;

    //       // and now we're at the opposite point
    //       // isAtStart = !isAtStart;
    //       let flyParams = {
    //         // These options control the ending camera position: centered at
    //         // the target, at zoom level 9, and north up.
    //         center: start,
    //         zoom: _zoom,
    //         bearing: _bearing,
    //         pitch: _pitch,

    //         // These options control the flight curve, making it move
    //         // slowly and zoom out almost completely before starting
    //         // to pan.
    //         speed: 1.5, // make the flying slow
    //         curve: 1, // change the speed at which it zooms out

    //         // This can be any easing function: it takes a number between
    //         // 0 and 1 and returns another number between 0 and 1.
    //         easing: (t) => t,

    //         // this animation is considered essential with respect to prefers-reduced-motion
    //         essential: true
    //       }

    //       map.current.flyTo(flyParams);
    //     })

  // const element = document.getElementById('titleblock')
  //   if (element.getAttribute('listener') !== 'true') element.addEventListener('click', () => {
  //     // depending on whether we're currently at point a or b, aim for
  //     // point a or b
  //     // const target = isAtStart ? end : start;

  //     // and now we're at the opposite point
  //     // isAtStart = !isAtStart;
  //     let flyParams = {
  //       // These options control the ending camera position: centered at
  //       // the target, at zoom level 9, and north up.
  //       center: start,
  //       zoom: _zoom,
  //       bearing: _bearing,
  //       pitch: _pitch,

  //       // These options control the flight curve, making it move
  //       // slowly and zoom out almost completely before starting
  //       // to pan.
  //       speed: 1.5, // make the flying slow
  //       curve: 1, // change the speed at which it zooms out

  //       // This can be any easing function: it takes a number between
  //       // 0 and 1 and returns another number between 0 and 1.
  //       easing: (t) => t,

  //       // this animation is considered essential with respect to prefers-reduced-motion
  //       essential: true
  //     }

  //     map.current.flyTo(flyParams);
  //   });

    if (filterEl.getAttribute('listener') !== 'true') {
      filterEl.addEventListener('keyup', (e) => {

        const value = normalize(`${e.target.value}`);
        if (value === '') {
          renderListings([])
          return map.current.fitBounds(searchableBBox.current)
        }

        // Filter visible features that match the input value.
        const filtered = [];


        for (const feature of searchables.current) {

          // console.log('searchables.current', searchables.current);

          // const keyword = 'AREA_SQM'
          // console.log('searchingKeywords', searchingKeywords.current);
          if (searchingKeywords.current) {
            [...searchingKeywords.current].forEach((keyword) => {
              const name = normalize(`${feature.properties[keyword]}`);
              if (name.includes(value)) {
                //|| code.includes(value)) {
                filtered.push(feature);
              }
            });
          }
        }

        // Populate the sidebar with filtered results
        renderListings(filtered);

        // Set the filter to populate features into the layer.
        if (false) { //(filtered.length) { //

          Array.from(searchingLayer.current).forEach(layerComponents => {
            let visibleLayers = []
            let targets = [layerComponents, layerComponents + "-label"]
            targets.forEach(layer => {
              if (map.current.getLayer(layer)) visibleLayers.push(layer)
            })
            visibleLayers.forEach(l => {
              map.current.setFilter(l, [
                'match',
                ['get', 'AREA_SQM'],
                filtered.map((feature) => {
                  return feature.properties['AREA_SQM'];
                }),
                true,
                false
              ]);
            })
          });
        }
      });

      filterEl.addEventListener('focus' , (e) => {
        const value = normalize(`${e.target.value}`);
        if (value === '') map.current.fitBounds(searchableBBox.current)
      })
    }
  });

  useEffect(() => {

    if (!map.current) return

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
      setBearing(map.current.getBearing().toFixed(2));
      setPitch(map.current.getPitch().toFixed(2));


    });
  });

  // useEffect(() => {

  //   async function checkGeoserver() {

  //     let geoserverUrl = 'http://sppsim.rtaf.mi.th'
  //     console.log(await webexists(geoserverUrl));

  //   }

  //   checkGeoserver()


  // });

  function toggleTerrain(event) {
    event.checked ? map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 }) : map.current.setTerrain();
 }


  function toggleSidebar(id) {

    const elem = document.getElementById(id);
    // Add or remove the 'collapsed' CSS class from the sidebar element.
    // Returns boolean "true" or "false" whether 'collapsed' is in the class list.

    const collapsed = elem.classList.toggle('collapsed');
    const duration = 1000
    const padding = {};
    // 'id' is 'right' or 'left'. When run at start, this object looks like: '{left: 300}';
    padding[id] = collapsed ? 0 : 200; // 0 if collapsed, 300 px if not. This matches the width of the sidebars in the .sidebar CSS class.
    // Use `map.easeTo()` with a padding option to adjust the map's center accounting for the position of sidebars.

    map.current.easeTo({
      padding: padding,
      duration // In ms. This matches the CSS transition duration property.
    });

    // console.log('collapsed', collapsed);
    // console.log('newMode', newMode);
    // console.log('padding', padding);
    // console.log('newMode === mode', newMode === mode);

    setTimeout(() => {
      console.log('setting arrow');
      console.log(collapsed);
      setToggleSymbol(collapsed ? "▶︎" : "◀︎")
    }, duration);
  }

  // useEffect(() => {

  //   console.log(spinners);
  // }, [spinners]);


  const SidebarContent = useMemo(() => {
    return (<LayersTOC mode={mode} />)
  }, [mode])

  const TitleBlock = () => {
    console.log('in TitleBlock');
    return (
    <Card sx={{ display: 'flex', bgcolor:"transparent", maxHeight : "15vh"}}>
      <CardMedia
          component="img"
          image="nkrafalogo.png"
          alt="nkrafa logo"
          sx={{  width:80, objectFit:'contain'}}
        />
      <CardContent>
      <Box sx={{ textAlign: 'center', typography:'h5', color:'white' }}>ระบบข้อมูลภูมิสารสนเทศของ รร.นนก. ณ ที่ตั้ง อ.มวกเหล็ก จว.สระบุรี</Box>
        </CardContent>
  </Card>)
  }



  // function openNav() {
  //   document.getElementById("mySidebar").style.width = "250px";
  //   document.getElementById("main").style.marginLeft = "250px";
  //   document.getElementById("openbtn").style.visibility = "hidden";
  // }

  // function closeNav() {
  //   document.getElementById("mySidebar").style.width = "0";
  //   document.getElementById("main").style.marginLeft= "0";
  //   document.getElementById("openbtn").style.visibility = "visible";
  // }


// return (
//     <React.Fragment>
//       <div ref={mapContainer} className="map-container" />
//       {/* <SidebarMenu {...sidebarmenuProps} /> */}
//       {/* <button id='openbtn' className="openbtn" onClick={openNav}>☰</button> */}

//       <div id="left" className="sidebar flex-center left collapsed">
//           <div className="sidebar-content rounded-rect flex-center">

//             <Stack spacing={2} direction="column" >

//               <LayersTOC />
//               {/* <BaseMaps /> */}
//             </Stack>
//             <div className="sidebar-toggle left" onClick={event => { toggleSidebar('left'); }}>
//             {toggleSymbol}
//             </div>
//           </div>
//       </div>


//      {/* <InfoBar {...info}/> */}
//       <div id='calculation-box' className="calculation-box">
//         <div id="calculated-area" />
//       </div>

//       <div id='titleblock'><TitleBlock /></div>
//       <div className='button-group-right'>
//       <Button component={Link} to="/comparemap" color="info" variant="contained"  size="small">โหมดเปรียบเทียบ</Button>
//         {/* <Button onClick={() => setCompareMode(b => !b)}   color="info" variant="contained"  size="small">โหมดเปรียบเทียบ</Button> */}
//         {/* <SearchBox /> */}
//       </div>

//       <div className="search_container">
//         <fieldset>
//         <input id="feature-filter" type="text" placeholder="ค้นหา" />
//         </fieldset>
//         <div id="feature-listing" className="listing" />
//       </div>
//     </React.Fragment>
//   )

return useMemo(() => {
  return (
    <React.Fragment>
      <div ref={mapContainer} className="map-container searchmode" />
      {/* <SidebarMenu {...sidebarmenuProps} /> */}
      {/* <button id='openbtn' className="openbtn" onClick={openNav}>☰</button> */}

      <div id="left" className="sidebar flex-center left collapsed">
          <div className="sidebar-content rounded-rect flex-center">
            <Stack spacing={2} direction="column" >
              <LayersTOC />
              {/* <BaseMaps /> */}
            </Stack>
            <div className="sidebar-toggle left" onClick={() => { toggleSidebar('left', ""); }}>
              {toggleSymbol}
            </div>
            {/* <div className="sidebar-search-toggle" onClick={() => { toggleSidebar('left', "search"); }}>
              {toggleSymbol}
            </div> */}
          </div>
      </div>


     {/* <InfoBar {...info}/> */}
      <div id='calculation-box' className="calculation-box">
            <Stack direction={"column"} sx={{ p:1, m:1}} className="calculated-area" >
              <div id="calculated-area" />
            </Stack>

      </div>

      {/* <div id='titleblock'><TitleBlock /></div> */}
      <div className='button-group-right'>
        {/* <Button onClick={() => setCompareMode(b => !b)}   color="info" variant="contained"  size="small">โหมดเปรียบเทียบ</Button> */}
        {/* <SearchBox /> */}
        <div id="search_container" className="search_container">
          <fieldset>
          <input id="feature-filter" type="text" placeholder="ค้นหา" />
          </fieldset>
          <div id="feature-listing" className="listing" />
        </div>
        <Button id="comparebutton" component={Link} to="/comparemap" color="info" variant="contained"  size="small">โหมดเปรียบเทียบ</Button>
      </div>


    </React.Fragment>
  )
}, [toggleSymbol])

}

export async function webexists(url) {
  const result = await fetch(url, { method: 'HEAD' });
  return result.ok;
}

export function normalize(string) {
  return string.trim().toLowerCase();
}

export function getUniqueFeatures(features, comparatorProperties) {
    const uniqueIds = new Set();
    const uniqueFeatures = [];
    for (const feature of features) {
      const id = comparatorProperties.map(p => feature.properties[p]).join("-") ;
      if (!uniqueIds.has(id)) {
        uniqueIds.add(id);
        uniqueFeatures.push(feature);
      }
    }
    return uniqueFeatures;
  }
